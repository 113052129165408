
import { Translate } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import * as React from 'react';
import LogoCareassur from "../../public/logo.png"

export function getRandomArbitrary(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function Loading(props) {

    return (
        <div  style={{ left: '50%', top: "25%", position: "fixed", width: "90%", zIndex: 2, transform: "translate(-50%, -50%)"}}  id="loading" className="loading">
          <img style={{width: "50%"}} src={LogoCareassur}/>
            <LinearProgress variant="determinate" value={props.progress} />
            <div><p style={{textAlign: "right"}}>{props.progress}%</p></div>
      </div>
    )
}

export default Loading;