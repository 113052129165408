import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// Emprunteur
import Pret from "../ComponentPret/Pret/Pret";
import Situation from "../ComponentPret/Situation/Situation";
import SituationCoSouscripteur from "../ComponentPret/Situation/SituationCoSouscripteur";
import Coordonees from "../ComponentPret/Coordonees/Coordonees";

// Prevoyance
import Prevoyance from "../ComponentPrevoyance/Prevoyance/Prevoyance";
import CoordoneesPrevoyance from "../ComponentPrevoyance/Coordonees/Coordonees";

function Routing() {
  const [changeComponent, setChangeComponent] = useState("pret");

  const goToSituation = () => {
    var pret = document.getElementById("pret");
    var situation = document.getElementById("situation");
    if (changeComponent !== "situation") {
      setChangeComponent("situation");
      pret.classList.remove("is-active");
      situation.classList.add("is-active");
    }
  };

  const goToSituationCoSouscripteur = () => {
    var pret = document.getElementById("pret");
    var situation = document.getElementById("situation");
    var situationCoSouscripteur = document.getElementById(
      "situationCoSouscripteur"
    );
    if (changeComponent !== "situationCoSouscripteur") {
      setChangeComponent("situationCoSouscripteur");
      pret.classList.remove("is-active");
      situation.classList.remove("is-active");
      situationCoSouscripteur.classList.add("is-active");
    }
  };

  const goToCoordonees = () => {
    var pret = document.getElementById("pret");
    var situation = document.getElementById("situation");
    var situationCoSouscripteur = document.getElementById(
      "situationCoSouscripteur"
    );
    var coordonees = document.getElementById("coordonees");

    if (changeComponent !== "coordonees") {
      setChangeComponent("coordonees");
      pret.classList.remove("is-active");
      situation.classList.remove("is-active");
      situationCoSouscripteur.classList.remove("is-active");
      coordonees.classList.add("is-active");
    }
  };

  const goToCoordoneesPrevoyance = () => {
    var prevoyance = document.getElementById("prevoyance");
    var coordonees = document.getElementById("coordoneesprevoyance");

    if (changeComponent !== "coordoneesprevoyance") {
      setChangeComponent("coordoneesprevoyance");
      prevoyance.classList.remove("is-active");
      coordonees.classList.add("is-active");
    }
  };

  const ComponentPret = () => {
    switch (changeComponent) {
      case "pret":
        return <Pret goToSituation={goToSituation} />;
      case "situation":
        return (
          <Situation
            goToSituationCoSouscripteur={goToSituationCoSouscripteur}
            goToCoordonees={goToCoordonees}
          />
        );
      case "situationCoSouscripteur":
        return <SituationCoSouscripteur goToCoordonees={goToCoordonees} />;
      case "coordonees":
        return <Coordonees />;
    }
  };

  useEffect(() => {
    <ComponentPret />;
  }, [changeComponent]);

  const devisFormRef = useRef(0);

  const ComponentPrevoyance = () => {
    switch (changeComponent) {
      case "pret":
        return <Prevoyance goToCoordonees={goToCoordoneesPrevoyance} />;
        case "coordoneesprevoyance":
          return (
            <CoordoneesPrevoyance
            />
          );
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route  path="/" element={<ComponentPret />} />
          <Route  path="/prevoyance" element={<ComponentPrevoyance />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
