import axios from "axios";

export const SAVE_PREVOYANCE      = "[INFORMATIONS] SAVE PREVOYANCE";
export const GET_CITY = "[ENVOI INFORMATIONS] GET CITY";
export const UPDATE_MESSAGE_ERROR = "[INFORMATIONS] UPDATE MESSAGE ERROR";



const url = "https://unim.forms-back.careassur.com";

let axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const getCity = (payload) => {
  const result = axios.get(
    url + "/quotes/cities?code=" + payload ,
    axiosConfig
  );
  return (dispatch) => {
    result.then((response) => {
      dispatch({
        type: GET_CITY,
        payload: response.data,
      });
    });
  };
};




export const savePrevoyance = (payload) => {
  return {
    type: SAVE_PREVOYANCE,
    payload: payload,
  };
};


export const updateMessageError = (payload) => {
  return {
    type: UPDATE_MESSAGE_ERROR,
    payload: payload
  }
}