import * as Actions from "../actions";

const initialState = {
  // TODO A ENLEVER
  city: [],
  pret_formulaire: {},
  souscripteur_formulaire: {},
  coSouscripteur_formulaire: {},
  nature: [
    {
      value: "Amortissable",
      label: "Amortissable",
    },
    {
      value: "In Fine",
      label: "In Fine",
    },
    {
      value: "Relais",
      label: "Relais",
    },
    {
      value: "CreditBail",
      label: "CreditBail",
    }
  ],
  type: [
    {
      value: "Privé",
      label: "Privé",
    },
    {
      value: "Professionel",
      label: "Professionel",
    },
  ],
  categorie: [
    {
      value: "Immobilier",
      label: "Immobilier",
    },
    {
      value: "Autre",
      label: "Autre ",
    },
  ],
  montant: [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2 ",
    },
    {
      value: "3",
      label: "3 ",
    },
    {
      value: "4",
      label: "4 ",
    },
    {
      value: "5",
      label: "5 ",
    },
    {
      value: "6",
      label: "6 ",
    },
    {
      value: "7",
      label: "7 ",
    },
    {
      value: "8",
      label: "8 ",
    },
    {
      value: "9",
      label: "9 ",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "26",
      label: "26",
    },
    {
      value: "27",
      label: "27",
    },
    {
      value: "28",
      label: "28",
    },
    {
      value: "29",
      label: "29",
    },
    {
      value: "30",
      label: "30",
    },
  ],
  profession: [
    {
      value: "133 CHIRURGIEN",
      label: "CHIRURGIEN",
    },
    {
      value: "102 CHIRURGIEN-DENTISTE",
      label: "CHIRURGIEN-DENTISTE",
    },
    {
      value: "204 INFIRMIERE",
      label: "INFIRMIERE",
    },
    {
      value: "203 MASSEUR-KINESITHERAPEUTE",
      label: "MASSEUR-KINESITHERAPEUTE",
    },
    {
      value: "101 MEDECIN GENERALISTE",
      label: "MEDECIN GENERALISTE",
    },
    {
      value: "103 ORTHODONTISTE",
      label: "ORTHODONTISTE",
    },
    {
      value: "303 PHARMACIEN SALARIE",
      label: "PHARMACIEN SALARIE",
    },
    {
      value: "143 PSYCHIATRE",
      label: "PSYCHIATRE",
    },
    {
      value: "106 VETERINAIRE",
      label: "VETERINAIRE",
    },
    {
      value: "404 ADHERENT CONJOINT MEDECIN HOSPI",
      label: "ADHERENT CONJOINT MEDECIN HOSPI",
    },
    {
      value: "401 ADHERENT CONJOINT MEDICAUX",
      label: "ADHERENT CONJOINT MEDICAUX",
    },
    {
      value: "402 ADHERENT CONJOINT PARAMEDICAUX",
      label: "ADHERENT CONJOINT PARAMEDICAUX",
    },
    {
      value: "403 ADHERENT CONJOINT PHARMA OPTICIEN",
      label: "ADHERENT CONJOINT PHARMA OPTICIEN",
    },
    {
      value: "208 AIDE SOIGNANT",
      label: "AIDE SOIGNANT",
    },
    {
      value: "131 ALLERGOLOGUE",
      label: "ALLERGOLOGUE",
    },
    {
      value: "233 AMBULANCIER",
      label: "AMBULANCIER",
    },
    {
      value: "130 ANESTHESISTE",
      label: "ANESTHESISTE",
    },
    {
      value: "110 ANGIOLOGUE",
      label: "ANGIOLOGUE",
    },
    {
      value: "109 ASSISTANT EN CHIRURGIE",
      label: "ASSISTANT EN CHIRURGIE",
    },
    {
      value: "220 AUDIO PROTHESISTE",
      label: "AUDIO PROTHESISTE",
    },
    {
      value: "209 AUTRES PARAMEDICAUX",
      label: "AUTRES PARAMEDICAUX",
    },
    {
      value: "111 AUTRES SPECIALISTES",
      label: "AUTRES SPECIALISTES",
    },
    {
      value: "134 BIOLOGISTE",
      label: "BIOLOGISTE",
    },
    {
      value: "132 CARDIOLOGUE",
      label: "CARDIOLOGUE",
    },
    {
      value: "201 CHIROPRACTEUR",
      label: "CHIROPRACTEUR",
    },
    {
      value: "149 DERMATOLOGUE",
      label: "DERMATOLOGUE",
    },
    {
      value: "227 DIETETICIENNE",
      label: "DIETETICIENNE",
    },
    {
      value: "152 DIRECTEUR DE LABORATOIRE",
      label: "DIRECTEUR DE LABORATOIRE",
    },
    {
      value: "150 ENDOCRINOLOGUE",
      label: "ENDOCRINOLOGUE",
    },
    {
      value: "221 ERGOTHERAPEUTE",
      label: "ERGOTHERAPEUTE",
    },
    {
      value: "226 ESTHETICIENNE",
      label: "ESTHETICIENNE",
    },
    {
      value: "229 ETIOPATHE",
      label: "ETIOPATHE",
    },
    {
      value: "105 ETUDIANT MEDICAUX",
      label: "ETUDIANT MEDICAUX",
    },
    {
      value: "212 ETUDIANT PARAMEDICAUX",
      label: "ETUDIANT PARAMEDICAUX",
    },
    {
      value: "136 GASTRO ENTEROLOGUE",
      label: "GASTRO ENTEROLOGUE",
    },
    {
      value: "135 GYNECOLOGUE",
      label: "GYNECOLOGUE",
    },
    {
      value: "107 INTERNE DES HOPITAUX",
      label: "INTERNE DES HOPITAUX",
    },
    {
      value: "230 LABORANTIN",
      label: "LABORANTIN",
    },
    {
      value: "205 MANIPULATEUR D'ELECTRORADIOLOGIE",
      label: "MANIPULATEUR D'ELECTRORADIOLOGIE",
    },

    {
      value: "199 MEDECIN",
      label: "MEDECIN",
    },
    {
      value: "508 MEDECIN HOSPITALIER",
      label: "MEDECIN HOSPITALIER",
    },
    {
      value: "108 MEDECIN HOSPITALIER CHU",
      label: "MEDECIN HOSPITALIER CHU",
    },
    {
      value: "139 NEUROLOGUE",
      label: "NEUROLOGUE",
    },
    {
      value: "138 O R L",
      label: "O R L",
    },
    {
      value: "137 OPHTALMOLOGUE",
      label: "OPHTALMOLOGUE",
    },
    {
      value: "302 OPTICIEN",
      label: "OPTICIEN",
    },

    {
      value: "218 ORTHOPEDISTE",
      label: "ORTHOPEDISTE",
    },
    {
      value: "207 ORTHOPHONISTE",
      label: "ORTHOPHONISTE",
    },
    {
      value: "231 ORTHOPROTHESISTE",
      label: "ORTHOPROTHESISTE",
    },
    {
      value: "206 ORTHOPTISTE",
      label: "ORTHOPTISTE",
    },
    {
      value: "202 OSTEOPATHE",
      label: "OSTEOPATHE",
    },
    {
      value: "234 PARM",
      label: "PARM",
    },
    {
      value: "145 PEDIATRE",
      label: "PEDIATRE",
    },
    {
      value: "210 PEDICURE",
      label: "PEDICURE",
    },
    {
      value: "301 PHARMACIEN TITULAIRE D'OFFICINE",
      label: "PHARMACIEN TITULAIRE D'OFFICINE",
    },
    {
      value: "140 PHLEBOLOGUE",
      label: "PHLEBOLOGUE",
    },
    {
      value: "141 PHTISIOLOGUE",
      label: "PHTISIOLOGUE",
    },
    {
      value: "142 PNEUMOLOGUE",
      label: "PNEUMOLOGUE",
    },
    {
      value: "211 PODOLOGUE",
      label: "PODOLOGUE",
    },
    {
      value: "228 PREPARATEURS EN PHARMACIE",
      label: "PREPARATEURS EN PHARMACIE",
    },
    {
      value: "222 PROTHESISTE",
      label: "PROTHESISTE",
    },
    {
      value: "217 PROTHESISTE DENTAIRE",
      label: "PROTHESISTE DENTAIRE",
    },

    {
      value: "219 PSYCHO REEDUCATEUR",
      label: "PSYCHO REEDUCATEUR",
    },
    {
      value: "224 PSYCHOLOGUE",
      label: "PSYCHOLOGUE",
    },
    {
      value: "200 PSYCHOMOTRICIEN",
      label: "PSYCHOMOTRICIEN",
    },
    {
      value: "225 PSYCHOTHERAPEUTE",
      label: "PSYCHOTHERAPEUTE",
    },
    {
      value: "223 PUERICULTRICE",
      label: "PUERICULTRICE",
    },
    {
      value: "148 RADIOLOGUE",
      label: "RADIOLOGUE",
    },
    {
      value: "215 REEDUCATEUR",
      label: "REEDUCATEUR",
    },
    {
      value: "999 RETRAITE MEDICAUX",
      label: "RETRAITE MEDICAUX",
    },
    {
      value: "998 RETRAITE PARAMEDICAUX",
      label: "RETRAITE PARAMEDICAUX",
    },
    {
      value: "144 RHUMATOLOGUE",
      label: "RHUMATOLOGUE",
    },
    {
      value: "151 SAGE FEMME",
      label: "SAGE FEMME",
    },
    {
      value: "501 SALARIE DE CABINET MEDICAL",
      label: "SALARIE DE CABINET MEDICAL",
    },
    {
      value: "502 SALARIE DE CABINET PARAMEDICAL",
      label: "SALARIE DE CABINET PARAMEDICAL",
    },
    {
      value: "232 SOPHROLOGUE",
      label: "SOPHROLOGUE",
    },
    {
      value: "146 STOMATOLOGUE",
      label: "STOMATOLOGUE",
    },
    {
      value: "147 UROLOGUE",
      label: "UROLOGUE",
    }
  ],

  enfants: [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3+" },
  ],
  regime: [
    { value: "Alsace Moselle", label: "Alsace Moselle" },
    { value: "Autres régimes spéciaux", label: "Autres régimes spéciaux" },
    { value: "CMU", label: "CMU" },
    { value: "MSA", label: "MSA" },
    { value: "Régime général", label: "Régime général" },
    { value: "TNS", label: "TNS" },
  ],
  situation: [
    { value: "Célibataire", label: "Célibataire" },
    { value: "Marié(e)", label: "Marié(e)" },
    { value: "Concubin(e)", label: "Concubin(e)" },
    { value: "Pascé(e)", label: "Pascé(e)" },
    { value: "Divorcé(e)", label: "Divorcé(e)" },
    { value: "Veuf(ve)", label: "Veuf(ve)" },
  ],
  differee: [
    { value: "Oui", label: "Oui" },
    { value: "Non", label: "Non" },
  ],
  tarif: [],
};

const formulaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_PRET: {
      return {
        ...state,
        pret_formulaire: action.payload,
      };
    }
    case Actions.SAVE_SITUATION: {
      return {
        ...state,
        souscripteur_formulaire: action.payload,
      };
    }
    case Actions.SAVE_SITUATION_CO_SOUSCRIPTEUR: {
      return {
        ...state,
        coSouscripteur_formulaire: action.payload,
      };
    }
    case Actions.GET_CITY: {
      return {
        ...state,
        city: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default formulaireReducer;
