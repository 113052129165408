export function objectFromAray(arr){
    if (arr === undefined)
      return []
    var newArrObj = []
    arr.map((element, id) => {
      newArrObj.push({
        'value': element,
        'label': element
      })
    })
    return newArrObj
  }


export function subsetOfObject(object, arrOfKey){
  var subset = {}
  for (const key of arrOfKey){
      subset = {...subset, [key]: object[key]};
    }
  return subset;
}