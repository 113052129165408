import React, { useState, useEffect } from "react";
import * as Actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading, { getRandomArbitrary } from "../../utils/Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  verifyTextInput,
  verifyAllInputs,
} from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent.js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axios from "axios";
import SelectComponent from "../../utils/input/SelectComponent.js";
import { objectFromAray } from "../../utils/input/inputUtils"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Error
let idLabel = [
  "adresse",
  "num",
  "telephone",
  "email",
  "ville",
  "code_postal",
];

let errorObject = {};
let formInit = {};
idLabel.forEach((element) => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
  formInit[element] = "";
});
errorObject.isAllGood = false;

function Coordonees(props) {
  const [errorState, setErrorState] = useState(errorObject);
  var { prevoyance_formulaire, city } =
    useSelector((state) => state.formulaireReducerPrevoyance.formulaire);

// console.log("prevoyance_formulaire", prevoyance_formulaire)

  const [activeLoading, setActiveLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [randomGen] = useState(getRandomArbitrary(15, 25));
  let errorMessage = "";
  const dispatch = useDispatch();
  const [formInputs, setFormInputs] = useState(formInit);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (activeLoading === true) {
      let timer = window.setTimeout(function () {
        const newProgress = progress + randomGen;
        if (newProgress < 100) {
          setProgress(newProgress);
        } else {
          setProgress(100);
          window.clearTimeout(timer);
        }
      }, 3600);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [progress, activeLoading]);

  //sumbit the form
  function handleSubmit(event) {
    event.preventDefault();
    // let isAllGood = verifyInputs(formInputs);
    setIsSubmitted(true);

    if (formInputs.différée === "Non") {
      delete formInputs.durée_mois;
      delete errorState.durée_mois;
      // console.log("formInputs", formInputs);
    }

    errorObject = verifyAllInputs(errorState);
    setErrorState(errorObject);
    // console.log("formInputs 2", typeof errorState.durée_mois);

    if (!errorObject.isAllGood) {
      return;
    }

    // Envoi de la requêtes
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    formInputs.remarques = document.getElementById("remarques").value;
    let coordonnees = formInputs;
    let send_to_back = { prevoyance_formulaire, coordonnees };
    axios
      .post(
        "https://emprunteur-back.unim.careassur.com" + "/careassur/devisassuranceemprunteur/createprevoyance",
        send_to_back,
        axiosConfig
      )
      .then((reponse) => {
        console.log("reponse", reponse);
      });
    setOpen(true);
  }

  useEffect(() => {
    if (formInputs.code_postal.length === 5) {
      dispatch(Actions.getCity(formInputs.code_postal));
    }
    setFormInputs({ ...formInputs, ville: "" });
    errorState["ville"].initialState = true;
  }, [formInputs.code_postal]);

  //
  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility:
        isSubmitted && errorState[elementName].error ? "visible" : "hidden",
      height: 13,
      fontSize: "0.7rem",
    };
    return warningStyle;
  }

  // Gestion particulière pour la date
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(Actions.updateMessageError(false));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
  var patternTextInput = "[a-zA-Z\u00C0-\u00FF- ]";
  const patternDigit = "[0-9]";
  const patterValuePhoneInput = "^0[1-9][0-9]{8}$";
  const patternEmail = "[a-zA-Z0-9+_.@-]";
  const patternValueEmail = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$";
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box id="boxContent" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid container item justifyContent={"center"}>
              <Grid item xs={11} sm={10}>
                <p className="main-form-title">
                  <b style={{ fontWeight: "400", paddingLeft: "10px" }}>
                    Vos Coordonées
                  </b>
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={{ xs: 4, sm: 6 }}
              justifyContent={"center"}
            >
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container spacing={1} item>
                      <Grid item xs={5} sm={3}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "N°",
                          "num",
                          "N°",
                          errorState["num"],
                          isSubmitted,
                          true,
                          "number",
                          { pattern: patternDigit, minRep: 1, maxRep: 3 },
                          verifyTextInput
                        )}
                      </Grid>
                      <Grid item xs={7} sm={9}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Adresse",
                          "adresse",
                          "adresse",
                          errorState["adresse"],
                          isSubmitted,
                          true,
                          "text",
                          { pattern: patternTextInput, minRep: 1, maxRep: 40 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["adresse"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"adresse"}
                          className="warning"
                          style={getWarningStyle("adresse")}
                        >
                          {errorState["adresse"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Complement d'adresse",
                          "complement_adresse",
                          "complement_adresse",
                          errorState["adresse"],
                          isSubmitted,
                          true,
                          "text",
                          { pattern: patternTextInput, minRep: 1, maxRep: 40 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Code Postal</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Ville</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Code postal",
                          "code_postal",
                          "postal",
                          errorState["code_postal"],
                          isSubmitted,
                          true,
                          "numeric",
                          { pattern: patternDigit, minRep: 5, maxRep: 5 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["code_postal"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"code_postal"}
                          className="warning"
                          style={getWarningStyle("code_postal")}
                        >
                          {errorState["code_postal"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          objectFromAray(city.cities),
                          "Ville",
                          "ville",
                          errorState["ville"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["ville"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"ville"}
                          className="warning"
                          style={getWarningStyle("ville")}
                        >
                          {errorState["ville"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Email</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Téléphone</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Email",
                          "email",
                          "Email",
                          errorState["email"],
                          isSubmitted,
                          true,
                          "email",
                          {
                            pattern: patternEmail,
                            minRep: 1,
                            maxRep: 30,
                            valuePattern: patternValueEmail,
                          },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["email"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"email"}
                          className="warning"
                          style={getWarningStyle("email")}
                        >
                          {errorState["email"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Téléphone",
                          "telephone",
                          "ex: 0712345678",
                          errorState["telephone"],
                          isSubmitted,
                          true,
                          "numeric",
                          {
                            pattern: patternDigit,
                            minRep: 10,
                            maxRep: 10,
                            valuePattern: patterValuePhoneInput,
                          },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={9}>
                    <p className="label-input">
                      Remarques ou informations particulières
                    </p>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item justifyContent={"center"} xs={9}>
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={5}
                          id="remarques"
                          placeholder="Remarques..."
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}

              <Grid
                style={{ paddingTop: 0 }}
                container
                item
                justifyContent={"center"}
              >
                <button id="continueInformation" className="button-form">
                  Recevoir mon devis
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="snackInformation"
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Votre devis a bien été envoyé, un conseiller vous contactera sous peu.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Coordonees;
