import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducerList';
const persistConfig = {
  key: 'root',
  storage: AsyncLocalStorage,
  whitelist: [''],
  blacklist: "",
};
// ['annotationsReducer', 'dashobardReducer', 'creationSessionReducer' , "sessionsReducer"]
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Middleware
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Redux: Store
const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk)),
);
// Middleware: Redux Persist Persister
const persistor = persistStore(store);

export {
  store,
  persistor,
};