import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from '@mui/material/FormHelperText';
import {verifySelect} from "./inputVerification"


function ChangeSelect(value, formInputs, setFormInputs, name){

  setFormInputs({ ...formInputs, [name]: value});
}

const inputStyle = {
  height: 30,
}

function SelectComponent(formInputs, setFormInputs, selectValues, label, name, elementErrorState, isSubmitted, disableHelperText=false) {
  return <FormControl className="formControlCSS" fullWidth 
  error={isSubmitted && elementErrorState.error} sx={inputStyle}>
    <InputLabel size="small" >{label} </InputLabel>
    <Select
      type="text"
      id={name}
      size="small"
      name={name}
      autoWidth={false}
      inputProps={{fontSize: 'small'}}
      MenuProps={{
        style: {
          autoFocus: false,
          maxHeight: 300,
          minHeight: 34,
          paddingTop: 3,
          paddingBottom: 5,
        },
      }}
      label={label}
      onChange={(e) => {
        const {name, value} = e.target
        ChangeSelect(value, formInputs, setFormInputs, name)
        var inputVerification = verifySelect(value)
        elementErrorState.initialState = false;
        elementErrorState.error = !inputVerification.isValid
        elementErrorState.helperText = inputVerification.helperText
      }}
      value={formInputs[name]}
    >
      {selectValues.map((element, id) => (
        <MenuItem key={id} value={element.value}>
          {element.label}
        </MenuItem>
      ))}


    </Select>
    <FormHelperText>{!disableHelperText && isSubmitted && elementErrorState.error ? elementErrorState.helperText : ""}</FormHelperText>
  </FormControl>;
}


export default SelectComponent;
