import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { borderRadius } from "@mui/system";

function ChangeInput(value, formInputs,  setFormInputs, name, index){

    if (index === null) {
      setFormInputs({ ...formInputs, [name]: value});
      return
    }
    var oldData = formInputs[index]
    setFormInputs({ ...formInputs, [index]: {...oldData, [name]: value}});
}


function VerifyInput(value, verificationFn, isSubmitted, patternObj, otherInfo) {
  // Pour vérifier si otherInfo est null. Il faut rajouter un typeof pour vérifier si une valeur est undefined
  var inputVerification = (typeof otherInfo === undefined || null === otherInfo)
    ? verificationFn(value, isSubmitted, patternObj)
    : verificationFn(value, otherInfo);
  return inputVerification;
}


export default function InputComponent(
  formInputs,
  setFormInputs,
  label,
  name,
  placeholder,
  elementErrorState,
  isSubmitted,
  disableHelperText,
  inputMode,
  patternObj,
  verificationFn,
  otherInfo = null,
  index = null
) {
  let inputVerification;
  const inputStyle = {
    height: 30,
    borderRadius: '50%'
  }
  return (
    <FormControl className="formControlCSS" fullWidth sx={inputStyle}>
      <TextField
        sx={{ borderRadius: '50%' }}
        error={isSubmitted && elementErrorState.error}
        helperText={!disableHelperText && isSubmitted && elementErrorState.error ? elementErrorState.helperText : ""}
        size="small"
        onChange={(event) => {
          const {name, value} = event.target
          // prevent value from having more than maxRep
          if (value.length > patternObj["maxRep"]){
              event.preventDefault();
              return;
          }
          ChangeInput(value, formInputs, setFormInputs, name, index);
          inputVerification = VerifyInput(value, verificationFn, isSubmitted, patternObj, otherInfo);
          elementErrorState.initialState = false;
          elementErrorState.error = !inputVerification.isValid;
          elementErrorState.helperText = inputVerification.helperText;
        }}
        onKeyPress={(event) => {
          if (!new RegExp(patternObj["pattern"]).test(event.key)) {
            event.preventDefault();
            return;
          }

        }}
        value={(index === null)? formInputs[name]: formInputs[index][name]}
        inputProps={{ inputMode: inputMode }}
        id={(index === null)? name: name+"_"+index}
        name={name}
        label={label}
        minLength="3"
        maxLength="20"
        placeholder={placeholder}
      />
    </FormControl>
  );
}
