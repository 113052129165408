import { isMoreThanAge, isValidDate } from "../dates/check.js";

function showError() {

}

function showSuccess() {

}

function showErrorDob() {

}

function showSuccessDob() {

}

export function verifyDob(dobValue, minAge, maxAge) {
  let comparison = isMoreThanAge(dobValue, minAge)
  // change to validate date then check more than age

  if (dobValue === null || dobValue === "")
    return {
      'isValid': false,
      'helperText': 'Champs obligatoire'
    }

  if (comparison === 'Invalid Date')
    return {
      'isValid': false,
      'helperText': "La date entrée est invalide"
    }

  if (!comparison)
    return {
      'isValid': false,
      'helperText': "minimum requis: " + minAge
    }

  if (isMoreThanAge(dobValue, maxAge))
    return {
      'isValid': false,
      'helperText': "maximum: " + (maxAge - 1)
    }


  return {
    'isValid': true,
    'helperText': ''
  }

}

export function verifyTextInput(textValue, isSubmitted, regExObj) {

  let repRegEx = new RegExp("^" + regExObj["pattern"] + "{" + regExObj["minRep"] + "," + regExObj["maxRep"] + "}$")
  var valueRegEx = ('valuePattern' in regExObj) ? new RegExp(regExObj["valuePattern"]) : new RegExp("^" + regExObj["pattern"] + "+$")


  if (textValue === undefined || textValue === "")
    return {
      'isValid': false,
      'helperText': 'Champs obligatoire'
    }


  if (!valueRegEx.test(textValue))
    return {
      'isValid': false,
      'helperText': 'Veuillez respecter le format du champs'
    }

  if (!repRegEx.test(textValue)) {
    if (textValue.length > regExObj["maxRep"])
      return {
        'isValid': false,
        'helperText': 'Limite de ' + regExObj["maxRep"] + ' caractères'
      }
    if (textValue.length < regExObj["minRep"])
      return {
        'isValid': false,
        'helperText': regExObj["minRep"] + " caractères minimum"
      }

  }


  return {
    'isValid': true,
    'helperText': ''
  }

}

export function verifySelect(selectedValue) {
  if (selectedValue === undefined || selectedValue === "")
    return {
      'isValid': false,
      'helperText': 'Champs obligatoire'
    }

  return {
    'isValid': true,
    'helperText': ''
  }

}


export function verifyMaxChildren(childrenValue, maxChildren) {
  if (maxChildren === 3) {
    maxChildren = 5
  }

  if (childrenValue === undefined || childrenValue === "")
    return {
      'isValid': false,
      'helperText': 'Champs obligatoire'
    }

  if (isNaN(childrenValue))
    return {
      'isValid': false,
      'helperText': "Veuillez entrer une valeur numérique valide"
    }

  if (childrenValue == 0)
    return {
      'isValid': false,
      'helperText': "Le nombre minimum d'enfants à assurer est 1."
    }

  if (childrenValue > maxChildren) {
    if (maxChildren == 5)
      return {
        'isValid': false,
        'helperText': "Le nombre d'enfants à assurer ne doit pas dépasser 5."
      }
    return {
      'isValid': false,
      'helperText': "Le nombre d'enfants à assurer ne doit pas dépasser le nombre d'enfants déclaré."
    }
  }


  return {
    'isValid': true,
    'helperText': ''
  }
}


export function verifyInputs(formInputs, savedData) {
  let errorObject = {};
  var verification;
  errorObject.isAllGood = true;

  for (const [key, value] of Object.entries(formInputs)) {
    if (key === 'is_comm_offers')
      continue;
    errorObject[key] = { error: false, helperText: "" }

    // Verifier si value est un string vide, null, undefined OU si value est un number ET si il est NaN
    if (value === "" || value === null || typeof value === 'undefined') {
      errorObject[key] = { error: true, helperText: "Le Champs obligatoire" }
      errorObject.isAllGood = false;
      continue;
    }

    if (key.includes("dob")) {
      verification = verifyDob(value)
      if (!verification.isValid) {
        errorObject[key] = { error: true, helperText: verification.helperText };
        errorObject.isAllGood = false;
      }
      continue;
    }

    if (key === 'num_insured_children') {
      verification = verifyMaxChildren(value, savedData.children)
      if (!verification.isValid) {
        errorObject[key] = { error: true, helperText: verification.helperText };
        errorObject.isAllGood = false;
      }
      continue;
    }


    if (key === "email") {
      if ((!/^\S+@\S+\.\S+$/.test(value))) {
        errorObject[key] = { error: true, helperText: "L'email est invalide" }
        errorObject.isAllGood = false;
        continue;
      }
      continue;
    }

  }
  return errorObject;
}


export function verifyChildrenInputs(arrayOfInputs) {
  let allIsGood = true;
  arrayOfInputs.map((element, index) => {
    for (const [key, value] of Object.entries(element)) {

      var keyInedx = key + "_" + index

      if (key.includes("dob")) {
        let comparison = isMoreThanAge(value, 31)
        if (comparison === 'Invalid Date') {
          showErrorDob("La date entrée est invalide", index);
          allIsGood = false;
          continue;
        }

        if (comparison) {
          showErrorDob("Les enfants doivent avoir moins de 31 ans.", index);
          allIsGood = false;
          continue;
        }

        showSuccessDob(index);
        continue
      }

      if (value === "" || value === null) {
        showError(keyInedx, "Champs obligatoire.");
        allIsGood = false;
        continue;
      }
      showSuccess(keyInedx);
    }

  })
  return allIsGood;
}

export function verifyAllInputs(globalErrorState) {
  var errorStateObj = { ...globalErrorState, isAllGood: true }
  for (const [key, value] of Object.entries(globalErrorState)) {
    if (key === "isAllGood")
      continue;

    if (value.error) {
      errorStateObj = { ...errorStateObj, isAllGood: false };
      continue;
    }
    if (value.initialState) {
      errorStateObj = {
        ...errorStateObj, isAllGood: false,
        [key]: { initialState: false, error: true, helperText: "Champs obligatoire" }
      };
    }

  }
  return errorStateObj
}