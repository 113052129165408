import React, { useState, useEffect } from "react";
import * as Actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading, { getRandomArbitrary } from "../../utils/Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  verifyTextInput,
  verifyAllInputs,
} from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent.js";
import SelectComponent from "../../utils/input/SelectComponent.js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import DayDate from "../../utils/dates/DayDate.js";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Error
let idLabel = [
  "categorie",
  "type",
  "annee_deblocage",
  "nature",
  "type",
  "taux",
  "duree_annee",
  "montant",
  "differee",
  "duree_mois",
];

let errorObject = {};
let formInit = {};
idLabel.forEach((element) => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
  formInit[element] = "";
});
errorObject.isAllGood = false;

function Pret(props) {
  var {
    nature,
    type,
    montant,
    montant,
    differee,
    categorie,
    errorSendInformations,
  } = useSelector((state) => state.formulaireReducer.formulaire);

  const [errorState, setErrorState] = useState(errorObject);

  const [nonFormattedDate, setNonFormattedDate] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [randomGen] = useState(getRandomArbitrary(15, 25));
  let errorMessage = "";
  const dispatch = useDispatch();
  const [formInputs, setFormInputs] = useState(formInit);

  useEffect(() => {
    if (activeLoading === true) {
      let timer = window.setTimeout(function () {
        const newProgress = progress + randomGen;
        if (newProgress < 100) {
          setProgress(newProgress);
        } else {
          setProgress(100);
          window.clearTimeout(timer);
        }
      }, 3600);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [progress, activeLoading]);

  //sumbit the form
  function handleSubmit(event) {
    event.preventDefault();
    // let isAllGood = verifyInputs(formInputs);
    setIsSubmitted(true);
    if (formInputs.differee === "Non") {
      delete formInputs.duree_mois;
      errorState.duree_mois = "0"
      // console.log("formInputs", formInputs);
    }

    errorObject = verifyAllInputs(errorState);
    setErrorState(errorObject);
    // console.log("formInputs 2", typeof errorState.duree_mois);

    if (!errorObject.isAllGood) {
      return;
    }

    // console.log("formInputs", formInputs);


    // setActiveLoading(true);
    // document.getElementById("loading").style.display = "block";
    // document.getElementById("boxContent").style.opacity = "0.1";
    // var button = document.getElementsByClassName("button-form")[0];
    // button.disabled = "true";
    // button.style.cursor = "progress";
    dispatch(Actions.savePret(formInputs));
    props.goToSituation();
  }

  //
  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility:
        isSubmitted && errorState[elementName].error ? "visible" : "hidden",
      height: 13,
      fontSize: "0.7rem",
    };
    return warningStyle;
  }

  // Gestion particulière pour la date
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(Actions.updateMessageError(false));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box id="boxContent" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid container item justifyContent={"center"}>
              <Grid item xs={11} sm={10}>
                <p className="main-form-title">
                  <b style={{ fontWeight: "400", paddingLeft: "10px" }}>
                    Votre Prêt
                  </b>
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={{ xs: 4, sm: 6 }}
              justifyContent={"center"}
            >
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Nature</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Type</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          nature,
                          "Nature",
                          "nature",
                          errorState["nature"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["nature"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"nature"}
                          className="warning"
                          style={getWarningStyle("nature")}
                        >
                          {errorState["nature"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          type,
                          "Type",
                          "type",
                          errorState["type"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["type"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"type"}
                          className="warning"
                          style={getWarningStyle("type")}
                        >
                          {errorState["type"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Catégorie</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Montant total en €</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          categorie,
                          "Catégorie",
                          "categorie",
                          errorState["categorie"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["categorie"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"categorie"}
                          className="warning"
                          style={getWarningStyle("categorie")}
                        >
                          {errorState["categorie"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                      {InputComponent(
                            formInputs,
                            setFormInputs,
                            "Montant en €",
                            "montant",
                            "€",
                            errorState["montant"],
                            isSubmitted,
                            true,
                            "number",
                            { pattern: "[0-9]", minRep: 1, maxRep: 10 },
                            verifyTextInput
                          )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["montant"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"montant"}
                          className="warning"
                          style={getWarningStyle("montant")}
                        >
                          {errorState["montant"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Durée en année</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">
                      Taux d’intérêt du prêt ​
                    </p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Durée en année",
                          "duree_annee",
                          "2",
                          errorState["duree_annee"],
                          isSubmitted,
                          true,
                          "number",
                          { pattern: "[0-9]", minRep: 1, maxRep: 2 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["duree_annee"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"duree_annee"}
                          className="warning"
                          style={getWarningStyle("duree_annee")}
                        >
                          {errorState["duree_annee"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Taux d'intérêt prêt",
                          "taux",
                          "%",
                          errorState["taux"],
                          isSubmitted,
                          true,
                          "number",
                          { pattern: "", minRep: 1, maxRep: 4 },
                          verifyTextInput
                        )}
                        <span style={{position: "relative", top: "8px", marginLeft: "-26px", fontWeight: "500"}}>%</span>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["taux"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"taux"}
                          className="warning"
                          style={getWarningStyle("taux")}
                        >
                          {errorState["taux"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Différé</p>
                  </Grid>

                  <Grid
                    style={{
                      display: formInputs.differee === "Oui" ? "block" : "none",
                    }}
                    item
                    xs={5.5}
                    sm={4.5}
                  >
                    <p className="label-input">Durée en mois</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          differee,
                          "Différé",
                          "differee",
                          errorState["differee"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["differee"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"differee"}
                          className="warning"
                          style={getWarningStyle("differee")}
                        >
                          {errorState["differee"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{
                      height: "50px",
                      display:
                        formInputs.differee === "Oui" ? "block" : "none",
                    }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item>
                        <Grid item xs={12}>
                          {InputComponent(
                            formInputs,
                            setFormInputs,
                            "Durée en mois",
                            "duree_mois",
                            "2",
                            errorState["duree_mois"],
                            isSubmitted,
                            true,
                            "number",
                            { pattern: "[0-9]", minRep: 1, maxRep: 2 },
                            verifyTextInput
                          )}
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid
                          marginLeft={1}
                          item
                          xs={0.75}
                          sm={0.5}
                          lg={0.25}
                        >
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["duree_mois"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"duree_mois"}
                            className="warning"
                            style={getWarningStyle("duree_mois")}
                          >
                            {errorState["duree_mois"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              {/*  */}
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Année de déblocage fonds</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {DayDate(
                          nonFormattedDate,
                          setNonFormattedDate,
                          formInputs,
                          setFormInputs,
                          "annee_deblocage",
                          errorState["annee_deblocage"],
                          isSubmitted,
                          true,
                          0,
                          100,
                          null,
                          "Date déblocage fonds"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["annee_deblocage"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"annee_deblocage"}
                          className="warning"
                          style={getWarningStyle("annee_deblocage")}
                        >
                          {errorState["annee_deblocage"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}

              <Grid
                style={{ paddingTop: 0 }}
                container
                item
                justifyContent={"center"}
              >
                <button id="continueInformation" className="button-form">
                  Continuer
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="snackInformation"
        open={errorSendInformations}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Une erreur est survenue. Veuillez réessayer.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Pret;
