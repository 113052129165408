import axios from "axios";

export const SAVE_PRET      = "[INFORMATIONS] SAVE PRET";
export const SAVE_SITUATION = "[INFORMATIONS] SAVE SITUATION";
export const SAVE_SITUATION_CO_SOUSCRIPTEUR = "[INFORMATIONS] SAVE SITUATION CO SOUSCRIPTEUR";
export const GET_CITY = "[ENVOI INFORMATIONS] GET CITY";

export const UPDATE_MESSAGE_ERROR = "[INFORMATIONS] UPDATE MESSAGE ERROR";



const url = "https://unim.forms-back.careassur.com";

let axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const getCity = (payload) => {
  const result = axios.get(
    url + "/quotes/cities?code=" + payload ,
    axiosConfig
  );
  return (dispatch) => {
    result.then((response) => {
      dispatch({
        type: GET_CITY,
        payload: response.data,
      });
    });
  };
};




export const savePret = (payload) => {
  return {
    type: SAVE_PRET,
    payload: payload,
  };
};

export const saveSituation = (payload) => {
  return {
    type: SAVE_SITUATION,
    payload: payload
  }
}

export const saveSituationCoSouscripteur = (payload) => {
  return {
    type: SAVE_SITUATION_CO_SOUSCRIPTEUR,
    payload: payload
  }
}

export const updateMessageError = (payload) => {
  return {
    type: UPDATE_MESSAGE_ERROR,
    payload: payload
  }
}