import React, { useState, useEffect } from "react";
import * as Actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading, { getRandomArbitrary } from "../../utils/Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  verifyTextInput,
  verifyAllInputs,
} from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent.js";
import SelectComponent from "../../utils/input/SelectComponent.js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import DayDate from "../../utils/dates/DayDate.js";

import {
  SelectChoice,
  UnselectChoice,
} from "../../utils/input/ChoiceComponent";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Error
let idLabel = [
  "nom",
  "civilite",
  "date_naissance",
  "type_activite",
  "situation",
  "prenom",
  "enfant",
  "profession",
  "chiffre_affaire",
  "revenu_annuel",
  "fumeur",
  "sport",
  "quotite_a_assurer",
  "cosouscripteur",
];
var patternTextInput = "[a-zA-Z\u00C0-\u00FF- ]";
let errorObject = {};
let formInit = {};
idLabel.forEach((element) => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
  formInit[element] = "";
});
errorObject.isAllGood = false;

function Situation(props) {
  var { situation, enfants, profession, errorSendInformations } = useSelector(
    (state) => state.formulaireReducer.formulaire
  );

  const [errorState, setErrorState] = useState(errorObject);

  const [nonFormattedDate, setNonFormattedDate] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [randomGen] = useState(getRandomArbitrary(15, 25));
  const dispatch = useDispatch();
  const [formInputs, setFormInputs] = useState(formInit);

  useEffect(() => {
    if (activeLoading === true) {
      let timer = window.setTimeout(function () {
        const newProgress = progress + randomGen;
        if (newProgress < 100) {
          setProgress(newProgress);
        } else {
          setProgress(100);
          window.clearTimeout(timer);
        }
      }, 3600);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [progress, activeLoading]);

  //sumbit the form
  function handleSubmit(event) {
    event.preventDefault();
    // console.log("formInputs SITUATION", formInputs);
    // let isAllGood = verifyInputs(formInputs);
    setIsSubmitted(true);

    errorObject = verifyAllInputs(errorState);
    setErrorState(errorObject);
    // if (formInputs.enfant === "Non") {
    //   delete formInputs.profession;
    //   delete errorState.profession;
    // }

    if (!errorObject.isAllGood) {
      return;
    }
    // console.log("formInputs", formInputs)
    // delete formInputs.cosouscripteur;
    dispatch(Actions.saveSituation(formInputs));
    // console.log("formInputs.cosouscripteu", formInputs)
    if (formInputs.cosouscripteur === "oui") props.goToSituationCoSouscripteur();
    if (formInputs.cosouscripteur === "non") props.goToCoordonees();
  }

  //
  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility:
        isSubmitted && errorState[elementName].error ? "visible" : "hidden",
      height: 13,
      fontSize: "0.7rem",
    };
    return warningStyle;
  }

  // Gestion particulière pour la date
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    // dispatch(Actions.updateMessageError(false));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  // Civilité
  function chooseMister() {
    errorState["civilite"].error = false;
    errorState["civilite"].initialState = false;
    const monsieurChoice = document.getElementById("monsieur");
    const madameChoice = document.getElementById("madame");
    SelectChoice(monsieurChoice);
    UnselectChoice(madameChoice);
    setFormInputs({ ...formInputs, civilite: "monsieur" });
  }

  function chooseMiss() {
    errorState["civilite"].error = false;
    errorState["civilite"].initialState = false;
    const monsieurChoice = document.getElementById("monsieur");
    const madameChoice = document.getElementById("madame");
    SelectChoice(madameChoice);
    UnselectChoice(monsieurChoice);
    setFormInputs({ ...formInputs, civilite: "madame" });
  }


  // Profession
  function activeSalarie() {
    errorState["type_activite"].error = false;
    errorState["type_activite"].initialState = false;
    const liberalChoice = document.getElementById("liberal");
    const salarieChoice = document.getElementById("salarie");
    SelectChoice(salarieChoice);
    UnselectChoice(liberalChoice);
    setFormInputs({ ...formInputs, type_activite: "salarie" });
  }


  function activeLiberal() {
    errorState["type_activite"].error = false;
    errorState["type_activite"].initialState = false;
    const liberalChoice = document.getElementById("liberal");
    const salarieChoice = document.getElementById("salarie");
    SelectChoice(liberalChoice);
    UnselectChoice(salarieChoice);
    setFormInputs({ ...formInputs, type_activite: "liberal" });
  }

  // Fumeur
  function yesFumeur() {
    errorState["fumeur"].error = false;
    errorState["fumeur"].initialState = false;
    const yesFumeurChoice = document.getElementById("yesFumeur");
    const noFumeurChoice = document.getElementById("noFumeur");
    SelectChoice(yesFumeurChoice);
    UnselectChoice(noFumeurChoice);
    setFormInputs({ ...formInputs, fumeur: "oui" });
  }

  function noFumeur() {
    errorState["fumeur"].error = false;
    errorState["fumeur"].initialState = false;
    const yesFumeurChoice = document.getElementById("yesFumeur");
    const noFumeurChoice = document.getElementById("noFumeur");
    SelectChoice(noFumeurChoice);
    UnselectChoice(yesFumeurChoice);
    setFormInputs({ ...formInputs, fumeur: "non" });
  }

  // Sport

  function yesSport() {
    errorState["sport"].error = false;
    errorState["sport"].initialState = false;
    const yesSportChoice = document.getElementById("yesSport");
    const noSportChoice = document.getElementById("noSport");
    SelectChoice(yesSportChoice);
    UnselectChoice(noSportChoice);
    setFormInputs({ ...formInputs, sport: "oui" });
  }

  function noSport() {
    errorState["sport"].error = false;
    errorState["sport"].initialState = false;
    const yesSportChoice = document.getElementById("yesSport");
    const noSportChoice = document.getElementById("noSport");
    SelectChoice(noSportChoice);
    UnselectChoice(yesSportChoice);
    setFormInputs({ ...formInputs, sport: "non" });
  }

  // Cosouscripteur

  function yesCoSouscripteur() {
    errorState["cosouscripteur"].error = false;
    errorState["cosouscripteur"].initialState = false;
    const yesCoSouscripteurChoice =
      document.getElementById("yesCoSouscripteur");
    const noCoSouscripteurChoice = document.getElementById("noCoSouscripteur");
    SelectChoice(yesCoSouscripteurChoice);
    UnselectChoice(noCoSouscripteurChoice);
    setFormInputs({ ...formInputs, cosouscripteur: "oui" });
  }

  function noCoSouscripteur() {
    errorState["cosouscripteur"].error = false;
    errorState["cosouscripteur"].initialState = false;
    const yesCoSouscripteurChoice =
      document.getElementById("yesCoSouscripteur");
    const noCoSouscripteurChoice = document.getElementById("noCoSouscripteur");
    SelectChoice(noCoSouscripteurChoice);
    UnselectChoice(yesCoSouscripteurChoice);
    setFormInputs({ ...formInputs, cosouscripteur: "non" });
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box id="boxContent" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid container item justifyContent={"center"}>
              <Grid item xs={11} sm={10}>
                <p className="main-form-title">
                  <b style={{ fontWeight: "400", paddingLeft: "10px" }}>
                    Votre Situation
                  </b>
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={{ xs: 1, sm: 1 }}
              justifyContent={"center"}
            >
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Civilité</p>
                  </Grid>
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">
                      Ajouter Co-souscripteur ?
                    </p>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  {/*  */}
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <Grid item xs={5.5}>
                          <a
                            style={{ marginLeft: "0" , width: "45px"}}
                            onClick={() => {
                              chooseMister();
                            }}
                            id="monsieur"
                            className="buttonGeneral-devis"
                          >
                            Mr
                          </a>
                        </Grid>
                        <Grid item xs={5.5}>
                          <a
                            style={{ marginLeft: "5px" , width: "45px"}}
                            onClick={() => {
                              chooseMiss();
                            }}
                            id="madame"
                            className="buttonGeneral-devis"
                          >
                            Mme
                          </a>
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["civilite"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"civilite"}
                            className="warning"
                            style={getWarningStyle("civilite")}
                          >
                            {errorState["civilite"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <Grid item xs={5.5}>
                          <a
                            style={{ marginLeft: "0" , width: "45px"}}
                            onClick={() => {
                              yesCoSouscripteur();
                            }}
                            id="yesCoSouscripteur"
                            className="buttonGeneral-devis"
                          >
                            Oui
                          </a>
                        </Grid>
                        <Grid item xs={5.5}>
                          <a
                            style={{ marginLeft: "5px" , width: "45px"}}
                            onClick={() => {
                              noCoSouscripteur();
                            }}
                            id="noCoSouscripteur"
                            className="buttonGeneral-devis"
                          >
                            Non
                          </a>
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted &&
                                errorState["cosouscripteur"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"cosouscripteur"}
                            className="warning"
                            style={getWarningStyle("cosouscripteur")}
                          >
                            {errorState["cosouscripteur"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}

              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Nom</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Prénom​</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Nom",
                          "nom",
                          "Smith",
                          errorState["nom"],
                          isSubmitted,
                          true,
                          "text",
                          { pattern: patternTextInput, minRep: 1, maxRep: 30 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["nom"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"nom"}
                          className="warning"
                          style={getWarningStyle("nom")}
                        >
                          {errorState["nom"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {InputComponent(
                          formInputs,
                          setFormInputs,
                          "Prénom",
                          "prenom",
                          "John",
                          errorState["prenom"],
                          isSubmitted,
                          true,
                          "text",
                          { pattern: patternTextInput, minRep: 1, maxRep: 30 },
                          verifyTextInput
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["prenom"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"prenom"}
                          className="warning"
                          style={getWarningStyle("prenom")}
                        >
                          {errorState["prenom"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Date de naissance</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Situation familiale</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {DayDate(
                          nonFormattedDate,
                          setNonFormattedDate,
                          formInputs,
                          setFormInputs,
                          "date_naissance",
                          errorState["date_naissance"],
                          isSubmitted,
                          true,
                          18,
                          68,
                          null,
                          "Date de naissance"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["date_naissance"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"date_naissance"}
                          className="warning"
                          style={getWarningStyle("date_naissance")}
                        >
                          {errorState["date_naissance"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          situation,
                          "Situation familiale",
                          "situation",
                          errorState["situation"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["situation"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"situation"}
                          className="warning"
                          style={getWarningStyle("situation")}
                        >
                          {errorState["situation"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                {/* <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Enfants fiscalement à charge</p>
                  </Grid>

                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Profession</p>
                  </Grid>
                </Grid> */}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item>
                      <Grid item xs={12}>
                        {SelectComponent(
                          formInputs,
                          setFormInputs,
                          enfants,
                          "Nbr d'enfants fiscalement à charge",
                          "enfant",
                          errorState["enfant"],
                          isSubmitted,
                          true
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f",
                            fontSize: 17,
                            visibility:
                              isSubmitted && errorState["enfant"].error
                                ? "visible"
                                : "hidden",
                          }}
                        ></ErrorIcon>
                      </Grid>
                      <Grid item>
                        <span
                          id={"enfant"}
                          className="warning"
                          style={getWarningStyle("enfant")}
                        >
                          {errorState["enfant"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item>
                        <Grid item xs={12}>
                          {SelectComponent(
                            formInputs,
                            setFormInputs,
                            profession,
                            "Profession",
                            "profession",
                            errorState["profession"],
                            isSubmitted,
                            true
                          )}
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["profession"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"profession"}
                            className="warning"
                            style={getWarningStyle("profession")}
                          >
                            {errorState["profession"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Type d’activité</p>
                  </Grid>
                  <Grid item xs={5.5} sm={4.5}>
                    {/* <p className="label-input">
                      Chiffre d’affaires annuel en €
                    </p> */}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  {/*  */}
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <Grid item xs={5.5}>
                          <a
                            style={{ marginLeft: "0" , width: "48px"}}
                            onClick={() => activeLiberal()}
                            id="liberal"
                            className="buttonGeneral-devis"
                          >
                            Libéral
                          </a>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                          <a
                              style={{ marginLeft: "8px" , width: "48px"}}
                            onClick={() => activeSalarie()}
                            id="salarie"
                            className="buttonGeneral-devis"
                          >
                            Salarié
                          </a>
                        </Grid>
                      </Grid>

                      <Grid container item xs={10} sm={7}>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["type_activite"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"type_activite"}
                            className="warning"
                            style={getWarningStyle("type_activite")}
                          >
                            {errorState["type_activite"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item>
                        <Grid item xs={12}>
                          {InputComponent(
                            formInputs,
                            setFormInputs,
                            "Chiffre d’affaires annuel",
                            "chiffre_affaire",
                            "€",
                            errorState["chiffre_affaire"],
                            isSubmitted,
                            true,
                            "number",
                            { pattern: "[0-9]", minRep: 1, maxRep: 10 },
                            verifyTextInput
                          )}
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted &&
                                errorState["chiffre_affaire"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"chiffre_affaire"}
                            className="warning"
                            style={getWarningStyle("chiffre_affaire")}
                          >
                            {errorState["chiffre_affaire"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">Etes-vous fumeur(euse)?</p>
                  </Grid>
                  <Grid item xs={5.5} sm={4.5}>
                    {/* <p className="label-input">Revenus annuels (brut) en €</p> */}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  {/*  */}
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <Grid item xs={5.5}>
                          <a
                              style={{ marginLeft: "0" , width: "45px"}}
                            onClick={() => yesFumeur()}
                            id="yesFumeur"
                            className="buttonGeneral-devis"
                          >
                            Oui
                          </a>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                          <a
                            style={{ marginLeft: "5px" , width: "45px"}}
                            onClick={() => noFumeur()}
                            id="noFumeur"
                            className="buttonGeneral-devis"
                          >
                            Non
                          </a>
                        </Grid>
                      </Grid>

                      <Grid container item xs={10} sm={7}>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["fumeur"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"fumeur"}
                            className="warning"
                            style={getWarningStyle("fumeur")}
                          >
                            {errorState["fumeur"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item>
                        <Grid item xs={12}>
                          {InputComponent(
                            formInputs,
                            setFormInputs,
                            "Revenus annuels (brut)",
                            "revenu_annuel",
                            "€",
                            errorState["revenu_annuel"],
                            isSubmitted,
                            true,
                            "number",
                            { pattern: "[0-9]", minRep: 1, maxRep: 10 },
                            verifyTextInput
                          )}
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["revenu_annuel"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"revenu_annuel"}
                            className="warning"
                            style={getWarningStyle("revenu_annuel")}
                          >
                            {errorState["revenu_annuel"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              {/*  */}
              <Grid container item spacing={1}>
                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  <Grid item xs={5.5} sm={4.5}>
                    <p className="label-input">
                      Pratique sport à risque ?
                    </p>
                  </Grid>
                  <Grid item xs={5.5} sm={4.5}>
                    {/* <p className="label-input">Quotité à assurer en %</p> */}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  columnSpacing={{ xs: 2, sm: 5 }}
                >
                  {/*  */}
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item columnSpacing={1}>
                        <Grid item xs={5.5}>
                          <a
                              style={{ marginLeft: "0" , width: "45px"}}
                            onClick={() => yesSport()}
                            id="yesSport"
                            className="buttonGeneral-devis"
                          >
                            Oui
                          </a>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                          <a
                              style={{ marginLeft: "5px" , width: "45px"}}
                            onClick={() => noSport()}
                            id="noSport"
                            className="buttonGeneral-devis"
                          >
                            Non
                          </a>
                        </Grid>
                      </Grid>

                      <Grid container item xs={10} sm={7}>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted && errorState["sport"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"sport"}
                            className="warning"
                            style={getWarningStyle("sport")}
                          >
                            {errorState["sport"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent={"center"}
                    xs={5.5}
                    sm={4.5}
                    style={{ height: "50px" }}
                  >
                    <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                      <Grid container item>
                        <Grid item xs={12}>
                          {InputComponent(
                            formInputs,
                            setFormInputs,
                            "Quotité à assurer",
                            "quotite_a_assurer",
                            "%",
                            errorState["quotite_a_assurer"],
                            isSubmitted,
                            true,
                            "number",
                            { pattern: "[0-9]", minRep: 1, maxRep: 3 },
                            verifyTextInput
                          )}
                            <span style={{position: "relative", top: "8px", marginLeft: "-26px", fontWeight: "500"}}>%</span>
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f",
                              fontSize: 17,
                              visibility:
                                isSubmitted &&
                                errorState["quotite_a_assurer"].error
                                  ? "visible"
                                  : "hidden",
                            }}
                          ></ErrorIcon>
                        </Grid>
                        <Grid item>
                          <span
                            id={"quotite_a_assurer"}
                            className="warning"
                            style={getWarningStyle("quotite_a_assurer")}
                          >
                            {errorState["quotite_a_assurer"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              <Grid
                style={{ paddingTop: 0 }}
                container
                item
                justifyContent={"center"}
              >
                <button id="continueInformation" className="button-form">
                  Continuer
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="snackInformation"
        open={errorSendInformations}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Une erreur est survenue. Veuillez réessayer.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Situation;
